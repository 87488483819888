"use client";
import { useForm } from "react-hook-form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import { Input } from "../ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../ui/select";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import { services } from "@/data/services";
import { sendFormToEmail } from "@/server/actions";

const formData = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email("Email must be valid"),
  phonenumber: z.string().min(1, "Phone number is required"),
  service: z.string().min(1, "Select a service"),
  message: z.string().optional(),
});

type ContactFormDataType = z.infer<typeof formData>;

export default function ContactForm() {
  const form = useForm<ContactFormDataType>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phonenumber: "",
      service: "",
      message: "",
    },
    resolver: zodResolver(formData),
  });
  const {
    formState: { isSubmitSuccessful, isSubmitting },
  } = form;

  async function onSubmit(data: ContactFormDataType) {
    await sendFormToEmail(data);
  }

  if (isSubmitSuccessful) {
    // form-submitted for google events
    return <div className="flex items-center justify-center form-submitted">{"Thank you. We will be in touch with you shortly."}</div>;
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-4">
        <div className="grid grid-cols-2 gap-4">
          <FormField
            name="firstName"
            control={form.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>First name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              );
            }}
          />
          <FormField
            name="lastName"
            control={form.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Last name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              );
            }}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <FormField
            name="email"
            control={form.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              );
            }}
          />
          <FormField
            name="phonenumber"
            control={form.control}
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Phone number</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              );
            }}
          />
        </div>

        <FormField
          name="service"
          control={form.control}
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Choose a service</FormLabel>
                <FormControl>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <SelectTrigger>
                      {field.value || "Choose a service"}
                    </SelectTrigger>
                    <SelectContent>
                      {services.map((service, idx) => (
                        <SelectItem key={idx} value={service.name}>
                          {service.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            );
          }}
        />

        <FormField
          name="message"
          control={form.control}
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Message</FormLabel>
                <FormControl>
                  <Textarea placeholder="Type a message..." {...field} />
                </FormControl>
              </FormItem>
            );
          }}
        />

        <div>
          {/* cta-button class for Google events */}
          <Button aria-label="Submit form to contact us" type="submit" className="cta-button">
            Enquire Now
          </Button>
        </div>
      </form>
    </Form>
  );
}
