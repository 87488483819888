import Section from "@/components/base/Section";
import Text from "@/components/text";
import { Button } from "@/components/ui/button";
import { Service, ServicePackage } from "@/types/service";
import Image from "next/image";
import Link from "next/link";

// packages

const buddhistFuneralPackage: ServicePackage = {
  id: "3-day-basic-package",
  name: "Buddhist  Funeral Package",
  description: "3 Days Basic Package $5500",
  includes: [
    {
      heading: "Casket and embalming",
      items: [
        "Half Glass Casket",
        "Casket Furnishing",
        [
          "Pillow",
          "Sutra Blanket",
          "Sandal Wood",
          "Sandal Powder",
          "Cotton Roll",
        ],
        "Embalming by Licensed Female Embalmer",
        [
          "Showering of Deceased",
          "Make-up for Deceased",
          "Dressing for Deceased",
        ],
      ],
    },
    {
      heading: "Professional Care Team",
      items: [
        "Shifting Deceased from Hospital or Home",
        "Transporting Deceased to Embalming Facility",
        "Sending Deceased to Wake Venue",
        "Booking of Cremation Slot and Arrangements",
        "Professional Service Team on Funeral Day",
        "3 Session Revered Monk Prayer (Encoffinment + Final Night + Funeral Day)",
        "Glass Hearse (Funeral Day)",
        "Pall-Bearers In Suits",
      ],
    },
    {
      heading: "Canvas Tentage",
      items: [
        "Void Deck Enclosed x 1 Unit",
        "Round Tables x 10 Units",
        "Square Tables x 15 Units",
        "Chairs x 100 Units",
        "Curtain & Carpet In Casket Enclosed",
        "Air Coolers x 2 Units",
        "Purposeful Furnishing",
        "Warmth Lighting Ambiance",
        "Buddhist Themed Backdrop",
        "Ceiling Fan x 4 Units",
        "Large Standing Fan x 2 Units",
        "General Lighting",
        "Mobile Toilet",
      ],
    },
    {
      heading: "Memorial Altar",
      items: [
        "Flower Arrangement x 2 Units",
        "1 enlarge photo size 10″(W) X 12″(H)",
        "6 pcs passport size photo",
        "Incense Pot",
        "Joss Sticks (Small / Medium / Large)",
        "Ancestral Tablet",
        "Deceased Chair",
        "Vegetarian Food Offering x 3 Sets",
        "Oil Lamp",
        "Red Gel Candles",
      ],
    },
    {
      heading: "Buddha Altar",
      items: [
        "Flower Arrangement x 2 Units",
        "Traditional Chinese Lamp",
        "Buddha Statue",
        "Food & Fruits Offering",
        "Buddha Incense Pot",
        "Incense Coil",
        "Lotus Gel Candles",
        "Glass Cup",
        "Oil Lamp",
      ],
    },
    {
      heading: "Other Essential",
      items: [
        "Guest Reception Area Setup",
        "Condolence Book",
        "Safe Box",
        "Stationary Set",
        "Funeral Notice",
        "45 Seater Air-condition Coach (2-way)",
        "Floral Water for Guests",
        "Appreciation Token for Guest",
        "Passport Size Photo Keepsake",
        "Ash Collection Service",
        "Post Funeral Guide",
        "Mobile Toilet",
        "Ash Collection Service with Complimentary Red Cloth and Paper Umbrella",
      ],
    },
  ],
};

const taoistFuneralPackage: ServicePackage = {
  id: "3-day-basic-package",
  name: "Taoist  Funeral Package",
  description: "3 Days Basic Package $7500",
  includes: [
    {
      heading: "Casket service",
      items: ["Half Glass Casket"],
    },
    {
      heading: "Embalming services",
      items: ["Professional Embalming, Makeup, and Dressing"],
    },
    {
      heading: "Professional Service Team",
      items: [
        "Collection of deceased from home or hospital",
        "Transportation of deceased to our embalming center",
        "Transportation of deceased from our embalming center back to void deck or funeral parlour",
        "Booking of cremation slot and administrative work",
        "Full suited funeral service crew on funeral day",
      ],
    },
    {
      heading: "Preparation of Taoist Ceremony",
      items: [
        "Void Decks",
        [
          "18″ Wake Enclosure Unit x 1",
          "Curtain Setup Unit x 1",
          "Carpet Setup Unit x 1",
          "General Lighting",
          "Round Table Unit x 10",
          "Square Table Unit x 15",
          "Chairs Unit x 100",
          "Standing Fan Unit x 6",
          "Mobile Toilet Unit x 1",
        ],
        "Taoist Funeral Setup",
        "Photo Enlargement",
        [
          "One Enlargement Photo 10″x12″",
          "Photo Frame 10″ x 12″ Unit x 1",
          "Passport Sized Photo Unit x 6",
        ],
        "Floral Arrangement",
        ["Photo Frame Wreath Unit x 1", "Deceased Table Unit x 2"],
        "For Recording Purpose",
        [
          "Condolence Book Unit x 1",
          "Plastic Folder Unit x 1",
          "Ball Point Pen Unit x 1",
        ],
      ],
    },
    {
      heading: "Coordination of Funeral Ceremony",
      items: [
        "Taoist Funeral Ceremonial Items",
        ["Priest Chanting Services", "Encoffin", "Last Night", "Funeral Day"],
        "Paper Products",
        [
          "Paper House Unit x 1",
          "Gold Mountain Unit x 1",
          "Silver Mountain Unit x 1",
          "Black Treasure Chest Unit x 1",
          "Red Clothing Chest Unit x 1",
          "Guy Paper Servant Unit x 1",
          "Girl Paper Servant Unit x 1",
        ],
        "Funeral Lanterns with Surname & Age Inscription",
        "Air Conditioned Bus",
        "Funeral Hearse",
        "Ashes Collection Services",
      ],
    },
  ],
};

const christianFuneralPackage: ServicePackage = {
  id: "christian-funeral-packages",
  name: "Christian Funeral Package",
  description: "3 Days Basic Package $4500",
  includes: [
    {
      heading: "Casket and Embalming",
      items: [
        "Half-Glass Casket",
        "Casket Furnishing",
        [
          "Plate Bearing Deceased's Name",
          "Plate Bearing Deceased's DOB & Date of Passing",
          "Cross",
        ],
        "Embalming by Licensed Female Embalmer",
        ["Showering for Deceased", "Make-up for Deceased"],
      ],
    },
    {
      heading: "Professional Care Team",
      items: [
        "Shifting Deceased from Hospital or Home",
        "Transporting Deceased to Embalming Facility",
        "Sending Deceased to Wake Venue",
        "Booking of Cremation Slot and Arrangements",
        "Professional Service Team on Funeral Day",
        "Glass Hearse (Funeral Day)",
        "Pall-Bearers in Suits",
      ],
    },
    {
      heading: "Canvas Tentage",
      items: [
        "Void Deck Enclosed x 1 Unit",
        "Round Tables x 10 Units",
        "Square Tables x 15 Units",
        "Chairs x 100 Units",
        "Curtain & Carpet In Casket Enclosed",
        [
          "Air Coolers x 2 Units",
          "Purposeful Furnishing",
          "Warmth Lighting Ambiance",
          "Christian Themed Backdrop",
        ],
        "Ceiling Fan x 4 Units",
        "Large Standing Fan x 2 Units",
        "General Lighting",
        "Mobile Toilet",
      ],
    },
    {
      heading: "Church Service",
      items: [
        "Flower Arrangement x 1 Units",
        "Photo Frame Wreath x 1 Unit",
        "Name Plate Bearing Deceased's name, DOB & Date of Passing",
      ],
    },
    {
      heading: "Other Essential",
      items: [
        "Coordination with Church for Prayer Service (Some Church only accept immediate family members to make arrangements)",
        "PA System",
      ],
    },
    {
      heading: "Professional Care Team",
      items: [
        "Guest Reception Area Setup",
        ["Condolence Book", "Safe Box", "Stationery Set", "Funeral Notice"],
        "Safe Entry Setup",
        ["Infrared Thermometer", "Safe Entry Check-In"],
        "45 Seater Air-condition Coach (2-way)",
        "Floral Water for Guests",
        "Passport Size Photo Keepsake x 6 Units",
        "Ash Collection Service",
        "Post Funeral Guide",
      ],
    },
  ],
};

const catholicFuneralPackage: ServicePackage = {
  id: "3-day-basic-package",
  name: "Catholic Funeral Package",
  description: "3 Days Basic Package $4500",
  includes: [
    {
      heading: "Casket and Embalming",
      items: [
        "Half-Glass Casket",
        "Casket Furnishing",
        [
          "Plate Bearing Deceased's Name",
          "Plate Bearing Deceased's DOB & Date of Passing",
          "Crucifix",
        ],
        "Embalming by Licensed Female Embalmer",
        ["Showering for Deceased", "Make-up for Deceased"],
      ],
    },
    {
      heading: "Professional Care Team",
      items: [
        "Shifting Deceased from Hospital or Home",
        "Transporting Deceased to Embalming Facility",
        "Sending Deceased to Wake Venue",
        "Booking of Cremation Slot and Arrangements",
        "Professional Service Team on Funeral Day",
        "Glass Hearse (Funeral Day)",
        "Pall-Bearers in Suits",
      ],
    },
    {
      heading: "Canvas Tentage",
      items: [
        "Void Deck Enclosed x 1 Unit",
        "Round Tables x 10 Units",
        "Square Tables x 15 Units",
        "Chairs x 100 Units",
        "Curtain & Carpet In Casket Enclosed",
        "Air Coolers x 2 Units",
        "Purposeful Furnishing",
        "Warmth Lighting Ambiance",
        "Catholic Themed Backdrop",
        "Ceiling Fan x 4 Units",
        "Large Standing Fan x 2 Units",
        "General Lighting",
        "Mobile Toilet",
      ],
    },
    {
      heading: "Memorial Altar",
      items: [
        "Flower Arrangement x 1 Unit",
        "Photo Frame Wreath x 1 Unit",
        "Name Plate Bearing Deceased's Name, DOB & Date of Passing",
        "Bottle of Holy Water",
      ],
    },
    {
      heading: "Funeral Mass Service",
      items: [
        "Coordination with Parishioner for Funeral Mass (Some Churches only accept immediate family members to make arrangements)",
      ],
    },
    {
      heading: "Other Essentials",
      items: [
        "Guest Reception Area Setup",
        ["Condolence Book", "Safe Box", "Stationery Set", "Funeral Notice"],
        "45 Seater Air-condition Coach (2-way)",
        "Floral Water for Guests",
        "Passport Size Photo Keepsake x 6 Units",
        "Ash Collection Service",
        "Post Funeral Guide",
      ],
    },
  ],
};

const directCremationFuneralPackage: ServicePackage = {
  id: "3-days-basic-package",
  name: "Direct Cremation Package",
  description: "3 Days Basic Package $5500",
  includes: [
    {
      heading: "Casket and Embalming",
      items: [
        "Half Glass Casket",
        [
          "Casket Furnishing",
          "Pillow",
          "Sutra Blanket",
          "Sandal Wood",
          "Sandal Powder",
          "Cotton Roll",
        ],
        "Embalming by Licensed Female Embalmer",
        [
          "Showering of Deceased",
          "Make-up for Deceased",
          "Dressing for Deceased",
        ],
      ],
    },
    {
      heading: "Professional Care Team",
      items: [
        "Shifting Deceased from Hospital or Home",
        "Transporting Deceased to Embalming Facility",
        "Sending Deceased to Wake Venue",
        "Booking of Cremation Slot and Arrangements",
        "Professional Service Team on Funeral Day",
        "3 Session Revered Monk Prayer (Encoffinment + Final Night + Funeral Day)",
        "Glass Hearse (Funeral Day)",
        "Pall-Bearers In Suits",
      ],
    },
    {
      heading: "Canvas Tentage",
      items: [
        "Void Deck Enclosed x 1 Unit",
        "Round Tables x 10 Units",
        "Square Tables x 15 Units",
        "Chairs x 100 Units",
        "Curtain & Carpet In Casket Enclosed",
        "Air Coolers x 2 Units",
        "Purposeful Furnishing",
        "Warmth Lighting Ambiance",
        "Buddhist Themed Backdrop",
        "Ceiling Fan x 4 Units",
        "Large Standing Fan x 2 Units",
        "General Lighting",
        "Mobile Toilet",
      ],
    },
    {
      heading: "Memorial Altar",
      items: [
        "Flower Arrangement x 2 Units",
        "1 Enlarged Photo Size 10″(W) X 12″(H)",
        "6 pcs Passport Size Photo",
        "Incense Pot",
        "Joss Sticks (Small / Medium / Large)",
        "Ancestral Tablet",
        "Deceased Chair",
        "Vegetarian Food Offering x 3 Sets",
        "Oil Lamp",
        "Red Gel Candles",
      ],
    },
    {
      heading: "Buddha Altar",
      items: [
        "Flower Arrangement x 2 Units",
        "Traditional Chinese Lamp",
        "Buddha Statue",
        "Food & Fruits Offering",
        "Buddha Incense Pot",
        "Incense Coil",
        "Lotus Gel Candles",
        "Glass Cup",
        "Oil Lamp",
      ],
    },
    {
      heading: "Other Essential",
      items: [
        "Guest Reception Area Setup",
        "Condolence Book",
        "Safe Box",
        "Stationery Set",
        "Funeral Notice",
        "45 Seater Air-condition Coach (2-way)",
        "Floral Water for Guests",
        "Appreciation Token for Guests",
        "Passport Size Photo Keepsake",
        "Ash Collection Service",
        "Post Funeral Guide",
        "Mobile Toilet",
        "Ash Collection Service with Complimentary Red Cloth and Paper Umbrella",
      ],
    },
  ],
};

// services

const buddhistFuneralService: Service = {
  id: "buddhist-funeral-service",
  image: "/services/buddhist.webp",
  name: "Buddhist Funeral Services",
  description:
    "Buddhist funeral rites with chanting led by monks to assist the deceased in transitioning towards the rebirth.",
  slug: "buddhist-funeral-service",
  about: {
    image: "/services/buddhist-about.webp",
    heading: "About Buddhist Funeral",
    body: (
      <div className="flex flex-col gap-8">
        <Text as="p" variant="body">
          {
            "Heavens Gate Buddhist Funeral Services offers a peaceful and respectful environment to support the soul of your loved one as it transitions into the next life."
          }
        </Text>
        <ul className="list-disc ml-4 space-y-2">
          <li>
            {
              "We manage every detail of the Buddhist funeral process with our in-house services, ensuring a seamless experience for families. By eliminating middleman costs, we’re able to offer:"
            }
          </li>
          <li>
            {
              "Affordable pricing – No additional markups, just fair, transparent rates"
            }
          </li>
          <li>
            {
              "Comprehensive services – We guide you and your family through each step, providing support and comfort during this difficult time."
            }
          </li>
        </ul>
        <Text as="p" variant="body">
          {
            "Our all-inclusive Buddhist funeral package covers everything from the preparation of the body and encoffining to cremation or burial, as well as the placement of ancestral tablets. With Heavens Gate, you can trust that every need will be handled with care and respect."
          }
        </Text>
      </div>
    ),
  },
  whyChoose: {
    image: "/services/buddhist-why-choose.webp",
    heading: "Why Choose Heavens Gate Buddhist Funeral Services?",
    body: "At Heavens Gate, we approach Buddhist funeral services with a deep respect for the principles of karma, reincarnation, and ancestral piety. Our goal is to provide a meaningful and respectful funeral experience that helps guide the departed soul while honoring the family’s traditions and values. We offer a comprehensive Buddhist funeral package designed to manage every aspect of the ceremony smoothly and with reverence. Our experienced funeral directors ensure that all services are carried out in a dignified manner, at a cost that remains accessible to all. Our commitment to compassion, respect, and reliability is at the heart of everything we do, providing families with peace of mind during a challenging time.",
  },
  packages: [buddhistFuneralPackage],
  gallery: [
    "/services/gallery/buddhist-taoist-1.webp",
    "/services/gallery/buddhist-taoist-2.webp",
    "/services/gallery/buddhist-taoist-3.webp",
    "/services/gallery/buddhist-taoist-4.webp",
    "/services/gallery/buddhist-taoist-5.webp",
    "/services/gallery/buddhist-taoist-6.webp",
  ],
  content: [
    <>
      <Section className="bg-background-accent">
        <div className="container flex flex-col md:grid md:grid-cols-2 items-center gap-8 md:gap-24">
          <div className="flex flex-col gap-4 md:gap-8">
            <Text as="h2" variant="sectionHeading" tone="primary">
              {"Our Complete Buddhist Funeral Services"}
            </Text>
            <Text as="p" variant="body">
              {
                "We offer a full range of services to support families with all aspects of Buddhist funeral customs, covering arrangements before, during, and after the funeral."
              }
            </Text>
            <Text as="p" variant="body">
              {"Our Buddhist funeral service package includes:"}
            </Text>
            <ul className="space-y-2 list-disc ml-4">
              <li>{"Preparation of the body and casket."}</li>
              <li>{"Rituals for the deceased’s enlightenment."}</li>
              <li>{"Coordination of funeral day activities."}</li>
              <li>{"Cremation or burial options."}</li>
              <li>{"Placement of the ancestral tablet."}</li>
              <li>{"Ash collection and optional sea burial services."}</li>
            </ul>
            <Text as="p" variant="body">
              {
                "Following the funeral, we provide post-funeral services such as the transport and installation of the ancestral tablet at a designated location, either at home or in a temple."
              }
            </Text>
            <div>
              <Button variant="default" asChild>
                <Link
                  aria-label="Learn more about Heavens Gate Funeral"
                  href="/contact"
                  target="_blank"
                >
                  Contact Us
                </Link>
              </Button>
            </div>
          </div>
          <Image
            src="/services/buddhist-more-about-packages.webp"
            width={512}
            height={512}
            alt=""
            className="w-full h-full object-cover aspect-[4/5] shadow order-first md:order-last"
            sizes="25vw"
          />
        </div>
      </Section>
      <Section>
        <div className="container max-w-5xl mx-auto flex flex-col gap-8">
          <Text as="h2" variant="sectionHeadingMd">
            {
              "Compassionate Buddhist Funeral Services to Support Your Loved One's Journey"
            }
          </Text>
          <Image
            src="/services/buddhist-content-1.webp"
            width={512}
            height={512}
            alt=""
            className="w-full h-full aspect-[16/9] object-cover"
            sizes="50vw"
          />
          <Text as="p" variant="body">
            {
              "Our Buddhist funeral services in Singapore are designed to guide the soul of the departed towards a peaceful rebirth while respecting the wishes of the family. We focus on supporting grieving relatives through every step of the process, ensuring the funeral and post-funeral rituals are carried out with care and empathy."
            }
          </Text>
          <Text as="p" variant="body">
            {
              "At Heavens Gate Funeral, we handle all aspects of the Buddhist funeral, from the initial arrangements to the post-funeral memorial ceremonies. This allows families to come together, reflect on the impermanence of life, and find comfort in their shared grief."
            }
          </Text>
          <Text as="p" variant="body">
            {
              "Our comprehensive, one-stop service not only helps minimize costs but also relieves families of the logistical burden, enabling them to focus on honoring their loved one and supporting their soul's transition to the next life."
            }
          </Text>
          <Image
            src="/services/buddhist-content-2.webp"
            width={512}
            height={512}
            alt=""
            className="w-full h-full aspect-[16/9] object-cover"
            sizes="50vw"
          />
          <Text as="p" variant="body">
            {"Expertly Chosen Monks for Your Loved One’s Journey"}
          </Text>
          <Text as="p" variant="body">
            {
              "In Chinese Buddhist funeral traditions, rituals continue for 49 days, the period it takes for the consciousness to move from this life to the next. The first 7 days are especially significant, during which experienced monks chant sutras to prepare the deceased’s soul for rebirth."
            }
          </Text>
          <Image
            src="/services/buddhist-content-3.webp"
            width={512}
            height={512}
            alt=""
            className="w-full h-full aspect-[16/9] object-cover"
            sizes="50vw"
          />
          <Text as="p" variant="body">
            {
              "We ensure that only qualified monks, specialists in guiding souls through the afterlife, lead these ceremonies, offering the highest level of spiritual care for your loved one’s transition."
            }
          </Text>
          <div>
            <Button variant="default" asChild>
              <Link
                aria-label="Learn more about Heavens Gate Funeral"
                href="/contact"
                target="_blank"
              >
                Contact Us
              </Link>
            </Button>
          </div>
        </div>
        ,
      </Section>
      ,
    </>,
  ],
};

const taoistFuneralService: Service = {
  id: "taoist-funeral-service",
  image: "/services/taoist.webp",
  name: "Taoist Funeral Services",
  slug: "taoist-funeral-service",
  description:
    "Taoist Funeral can be complicated due to the different dialect groups and custom requirements. Let us help you with it.",
  about: {
    image: "/services/taoist-about.webp",
    heading: "About Taoist Funeral",
    body: "During Taoist funeral rituals, the Taoist priest will guide the family and relatives in a ceremonial procession. Additionally, the head of the family will carry the deceased's photograph throughout the funeral march.",
  },
  packages: [taoistFuneralPackage],
  gallery: [
    "/services/gallery/buddhist-taoist-1.webp",
    "/services/gallery/buddhist-taoist-2.webp",
    "/services/gallery/buddhist-taoist-3.webp",
    "/services/gallery/buddhist-taoist-4.webp",
    "/services/gallery/buddhist-taoist-5.webp",
    "/services/gallery/buddhist-taoist-6.webp",
  ],
};

const christianFuneralService: Service = {
  id: "christian-funeral-service",
  image: "/services/christian.webp",
  name: "Christian Funeral Services",
  slug: "christian-funeral-service",
  description:
    "Christian Funeral are mostly in celebration of one's life and hence a more cheerful and hopeful setting are often observed in a Christian funeral.",
  about: {
    image: "/services/christian-about.webp",
    heading: "About Christian Funeral ",
    body: "Heavens Gate offers complete Christian funeral services and packages designed to help families honor their loved ones with dignity and provide a meaningful farewell, guided by the hop e of eternal peace in heaven.\n\nWe recognize the Christian belief in the resurrection of the body, as exemplified by the resurrection of Jesus Christ, who atoned for the sins of humanity. With this in mind, our Christian funeral services are centered around facilitating prayers and ceremonies that allow the family and loved ones to seek forgiveness for the departed's sins and pray for their soul's peaceful journey to the afterlife.\n\nOur aim is to create a comforting and respectful environment where families can find solace in their faith, reflect on the promise of resurrection, and offer prayers for the eternal peace of the soul.",
  },
  whyChoose: {
    image: "/services/christian-why-choose.webp",
    heading: "About Christian Funeral ",
    body: "At Heavens Gate, we offer a seamless, one-stop Christian funeral service, with every aspect of the process managed in-house. This ensures that you receive the highest standard of care and attention to detail, from start to finish, all handled by our experienced funeral directors.\n\nBy eliminating the need for third-party vendors, we are able to keep our services affordable without compromising on quality. Our goal is to provide you with a respectful and compassionate service that helps you honor your loved one’s memory while supporting you through the grieving process.\n\nWith a foundation built on sincerity, respect, and reliability, Heavens Gate is here to guide you every step of the way, offering comfort and peace of mind during this difficult time.",
  },
  content: [
    <Section key="christian-funeral-service">
      <div className="container max-w-5xl mx-auto flex flex-col gap-8">
        <Text as="h2" variant="sectionHeadingMd">
          Benefits of Our Christian Funeral Service
        </Text>
        <Text as="p" variant="body">
          {
            "Our Christian funeral service is designed to give you peace of mind by handling all the practical aspects of the funeral, allowing you to focus on honoring the soul of your loved one. We take care of the details so you can fully immerse yourself in the sacred act of remembrance and prayer."
          }
        </Text>
        <Text as="p" variant="body">
          {
            "No matter your Christian denomination, we ensure the service aligns with your specific faith traditions, carried out with the dignity and reverence your loved one deserves."
          }
        </Text>
        <Image
          src="/services/christian-content-1.webp"
          width={512}
          height={512}
          alt=""
          className="w-full h-full aspect-[16/9] object-cover"
          sizes="50vw"
        />
        <Text as="p" variant="body">
          {
            "We encourage the active participation of family and friends in the service, allowing them to:"
          }
        </Text>
        <ul className="ml-4 list-disc space-y-2">
          <li>Serve as pallbearers</li>
          <li>Deliver eulogies</li>
          <li>Present floral tributes</li>
          <li>Take part in a post-funeral memorial service</li>
        </ul>
        <Text as="p" variant="body">
          {
            "We encourage the active participation of family and friends in the service, allowing them to:"
          }
        </Text>
        <Image
          src="/services/christian-content-2.webp"
          width={512}
          height={512}
          alt=""
          className="w-full h-full aspect-[16/9] object-cover"
          sizes="50vw"
        />
        <Text as="h3" variant="body">
          Expertise in Cremation Services
        </Text>
        <Text as="p" variant="body">
          {
            "While cremation has become more accepted in Christian funerals in Singapore, we understand that some families may have concerns, especially those who adhere to traditional views on burial. As experienced funeral directors, we are well-versed in arranging cremation services and coordinating with the government-run Mandai Crematorium. We provide guidance to ensure the process is handled respectfully and in accordance with your family's beliefs."
          }
        </Text>
        <div>
          <Button variant="default" asChild>
            <Link
              aria-label="Learn more about Heavens Gate Funeral"
              href="/contact"
              target="_blank"
            >
              Contact Us
            </Link>
          </Button>
        </div>
      </div>
      ,
    </Section>,
  ],
  packages: [christianFuneralPackage],
  videos: [
    "/services/videos/christian-1.mp4",
    "/services/videos/christian-2.mp4",
  ],
  gallery: [
    "/services/gallery/christian-catholic-1.webp",
    "/services/gallery/christian-catholic-2.webp",
    "/services/gallery/christian-catholic-3.webp",
    "/services/gallery/christian-catholic-4.webp",
    "/services/gallery/christian-catholic-5.webp",
    "/services/gallery/christian-catholic-6.webp",
  ],
};

const catholicFuneralService: Service = {
  id: "catholic-funeral-service",
  image: "/services/catholic.webp",
  name: "Catholic Funeral Services",
  slug: "catholic-funeral-service",
  description:
    "Catholics are more inclined to use pictures of Jesus for their backdrop. We also help coordinate the Funeral Mass on the wake's final day.",
  about: {
    image: "/services/catholic-about.webp",
    heading: "About Catholic Funeral",
    body: "A catholic funeral is a religion service that's held for family and friends to come together to pray for the dealy departed soul, celebrate its life and memories.",
  },
  packages: [catholicFuneralPackage],
  videos: [
    "/services/videos/christian-1.mp4",
    "/services/videos/christian-2.mp4",
  ],
  gallery: [
    "/services/gallery/christian-catholic-1.webp",
    "/services/gallery/christian-catholic-2.webp",
    "/services/gallery/christian-catholic-3.webp",
    "/services/gallery/christian-catholic-4.webp",
    "/services/gallery/christian-catholic-5.webp",
    "/services/gallery/christian-catholic-6.webp",
  ],
};

const directCremationFuneralService: Service = {
  id: "direct-cremation-funeral-service",
  image: "/services/direct-cremation.webp",
  name: "Direct Cremation Funeral Services",
  slug: "direct-cremation-service",
  description:
    "Suitable for small families or those who do not wish to have a funeral wake, direct cremation package offer a short memorial instead",
  about: {
    image: "/services/direct-cremation-about.webp",
    heading: "About Direct Cremation",
    body: "Direct cremation are for those whose wishes a simple funeral without any religion service. Suitable for smaller families and those who prefer without a wake.",
  },
  packages: [directCremationFuneralPackage],
  gallery: [],
};

export const services: Service[] = [
  buddhistFuneralService,
  taoistFuneralService,
  christianFuneralService,
  catholicFuneralService,
  directCremationFuneralService,
];
